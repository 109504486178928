@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP);
/* https://websemantics.uk/tools/responsive-font-calculator/ */
@media (min-width: 480px) {
  :root {
    font-size: calc(0.875rem + ((1vw - 4.8px) * 0.1389));
    min-height: 0vw;
  }
}
@media (min-width: 1920px) {
  :root {
    font-size: 16px;
  }
}

body {
  margin: 0;
  font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, '游ゴシック体', 'Yu Gothic', 'YuGothic', 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-fab {
  z-index: 3;
  position: fixed;
  bottom: 45px;
  right: 24px;
}

/* 必須項目 */
.form-group.required label:first-child:after {
  margin: -0.2em 0 0 0.2em;
  content: '*';
  color: #DB2828;
}

